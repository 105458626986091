import React, { useEffect, useState } from "react";
import Container from "../../Shared/Container/Container";
import { Button, Card, Carousel, Spinner } from "flowbite-react";
import { HiOutlineArrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { db } from "../../../firebase/firebaseConfig";
import {
  collection,
  DocumentData,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { Certificate } from "../../../interfaces/Certificate";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

function MainAbout() {
  const { t, i18n } = useTranslation();
  const [certificates, setCertificates] = useState<Certificate[]>();
  useEffect(() => {
    const fetchData = async () => {
      const certQuerySnapshot = await getDocs(collection(db, "certificates"));
      const certs = certQuerySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
        ({
          id: doc.id,
          ...doc.data(),
        } as Certificate)
      );
      setCertificates(certs);
    };

    fetchData();
  }, []);

  if (!certificates) {
    return <Spinner />;
  }
  return (
    <div
      className="relative bg-repeat" // Apply the animated background and breathing effect
      style={{
        backgroundImage: `url('/images/backgrounds/background-dots-01.png')`,
        backgroundSize: "150px 150px", // Initial size of the dots
      }}
    >
      <div className="flex flex-col gap-4 lg:gap-0 py-8">
        <div className="mb-8">
          <h4 className="text-gray-600 text-center text-lg lg:text-xl font-bold">
            {/* {t("whoAreWe")} */}
          </h4>
          <h3 className="text-main-blue text-center text-2xl lg:text-4xl font-bold">
            {t("aboutUs")} {/* Translation for "ჩვენ შესახებ" */}
          </h3>
        </div>
        <div className="relative flex items-center justify-center w-full py-8">
          <Container className="h-full flex flex-col lg:flex-row justify-center items-center relative px-4">
            <div className="flex flex-col lg:flex-row justify-between items-center w-full min-h-[384px] lg:min-h-[436px] gap-4">
              {/* Card Section */}
              <Card
                className="max-w-xl w-full lg:w-auto rounded-xl px-4
                !h-full z-50 min-h-[364px] flex flex-col justify-center"
              >
                {/* <h4 className="text-main-red font-bold sm:text-xl lg:text-xl">
                  {t("moreThanAAgency")}{" "}
                </h4> */}
                <div
                  className=""
                  dangerouslySetInnerHTML={{ __html: t("EllIs") }}
                ></div>
                <h5 className="text-gray-500 text-sm lg:text-lg">
                  <ul className="list-disc translate-x-6 text-main-blue lg:text-lg">
                    <li>{t('EnglishUkPartnerAgency')}</li>
                    <li>{t('ICEFAgency')}</li>
                    <li>{t('BritishCouncilAdvantage')}</li>
                    <li>{t('CambridgeEnglishLanguageAsses')}</li>
                  </ul>
                </h5>
                <Link to={"/about"}>
                  <Button color={"main-red"} className="py-1 w-fit px-4">
                    {t("learnMore")} {/* Translation for "გაიგე მეტი" */}
                    <HiOutlineArrowRight className="ml-2 h-5 w-5" />
                  </Button>
                </Link>
              </Card>

              {/* Image Section */}
              <Carousel className="max-h-[364px] translate-y-[2px]" indicators={false} rightControl={<></>} leftControl={<></>}>
                {certificates.map((photo, idx) => (
                  <Zoom>
                    <img
                      key={photo.id}
                      src={photo.imageUrl}
                      alt={`Fare ${photo.id}`}
                      className={`h-full w-full object-contain max-h-[364px] object-${idx === 0 ? 'center' : 'top'}`}
                    />
                  </Zoom>
                ))}
              </Carousel>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default MainAbout;
