import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Service } from "../../interfaces/Service";
import SquareServicePreviewModal from "../SquareServicePreviewModal/SquareServicePreviewModal";

interface ISquarePreviewService {
  service: Service;
}

function SquarePreviewService({ service }: ISquarePreviewService) {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  // Display name based on the current language
  const displayName = i18n.language === "en" ? service.nameEn : service.name;

  return (
    <>
      <div
        className="bg-main-red w-full h-full max-h-40 text-white flex flex-col items-center justify-center min-h-20
    hover:bg-red-700 hover:cursor-pointer duration-75"
        onClick={() => setOpen(!open)}
      >
        <h4 className="text-center text-sm">{displayName}</h4>
      </div>
      <SquareServicePreviewModal openModal={open} service={service} setOpenModal={setOpen} />
    </>
  );
}

export default SquarePreviewService;
