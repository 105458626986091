import React, { useState } from "react";
import { Table, Button } from "flowbite-react";
import { Service } from "../../../../interfaces/Service";
import UpdateServiceModal from "../Modals/UpdateServiceModal";

interface AdminServicesTableRowProps {
  service: Service;
  onEditClick: () => void;
  onDeleteClick: () => void;
}

const AdminServicesTableRow: React.FC<AdminServicesTableRowProps> = ({
  service,
}) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
        <Table.Cell>
          <img src={service.imageUrl} alt={service.name} className="h-10" />
        </Table.Cell>
        <Table.Cell>{service.name}</Table.Cell>
        <Table.Cell>{service.order}</Table.Cell>
        <Table.Cell>
          <a
            onClick={() => setOpenModal(true)}
            className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 hover:cursor-pointer"
          >
            შევცლა
          </a>
        </Table.Cell>
      </Table.Row>
      <UpdateServiceModal
        service={service}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

export default AdminServicesTableRow;
