import { Table } from "flowbite-react";
import React, { useState } from "react";
import { FAQ } from "../../../../interfaces/FAQ";
import EditFaqModal from "../Modal/EditFaqModal";

function AdminFaqTableRow(FAQ: FAQ) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {FAQ.question}
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {FAQ.answerHTML.slice(0, 20)}
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {FAQ.order}
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white"></Table.Cell>
        <Table.Cell>
          <a
            onClick={() => setOpenModal(true)}
            className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 hover:cursor-pointer"
          >
            შევცლა
          </a>
        </Table.Cell>
      </Table.Row>
      <EditFaqModal
        FAQ={FAQ}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  );
}

export default AdminFaqTableRow;
