import React, { useState } from "react";
import { Service } from "../../../../interfaces/Service";
import { Button, FileInput, Label, Modal, TextInput } from "flowbite-react";
import { deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase/firebaseConfig";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { object, string, number } from "yup";
import { useNavigate } from "react-router";
import HtmlEditor from "../../../../components/HtmlEditor/HtmlEditor";

interface EditServiceModalProps {
  service: Service;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function EditServiceModal({
  service,
  openModal,
  setOpenModal,
}: EditServiceModalProps) {
  const [name, setName] = useState(service.name);
  const [nameEn, setNameEn] = useState(service.nameEn); // English name
  const [descriptionHTML, setDescriptionHTML] = useState(service.descriptionHTML);
  const [descriptionHTMLEn, setDescriptionHTMLEn] = useState(service.descriptionHTMLEn); // English description
  const [order, setOrder] = useState(service.order);
  const [file, setFile] = useState<File | null>(null);
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const navigate = useNavigate();

  let serviceSchema = object({
    name: string().required("სახელი აუცილებელია!"),
    nameEn: string().required("English name is required!"),
    order: number().required("რიგი აუცილებელია!"),
    imageUrl: string().required("სურათი აუცილებელია!"),
    descriptionHTML: string().required("აღწერა აუცილებელია!"),
    descriptionHTMLEn: string().required("English description is required!"),
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleSave = async () => {
    if (!service) {
      setErrorMessages(["No service selected for editing."]);
      return;
    }

    try {
      let imageUrl = service.imageUrl;

      if (file) {
        const storage = getStorage();
        const storageRef = ref(storage, `images/${file.name}`);
        await uploadBytes(storageRef, file);
        imageUrl = await getDownloadURL(storageRef);
      }

      await serviceSchema.validate({
        name,
        nameEn,
        order,
        imageUrl,
        descriptionHTML,
        descriptionHTMLEn,
      });

      const updatedService: Service = {
        id: service.id,
        name,
        nameEn,
        order,
        imageUrl,
        descriptionHTML,
        descriptionHTMLEn,
      };

      const serviceDocRef = doc(db, "services", service.id);
      await setDoc(serviceDocRef, updatedService, { merge: true });
      await updateDoc(serviceDocRef, updatedService as { [x: string]: any });

      setOpenModal(false);
      navigate(0);
    } catch (e: any) {
      console.error(e);
      setErrorMessages(e.errors);
    }
  };

  const handleDelete = async (serviceId: string) => {
    setButtonsDisabled(true);
    try {
      const serviceDocRef = doc(db, "services", serviceId);
      await deleteDoc(serviceDocRef);
      navigate(0); // Refresh the page or update the state to reflect changes
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
    setButtonsDisabled(false);
  };

  return (
    <Modal
      dismissible
      show={openModal}
      onClose={() => setOpenModal(false)}
      className="font-mtavvruli"
    >
      <Modal.Header className="text-main-blue font-bold">
        შეცვალე <span className="text-main-red">{service.name}</span>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-6 w-full">
          <div className="flex flex-col gap-4">
            <div>
              <Label htmlFor="name" value="სახელი" />
              <TextInput
                id="name"
                type="text"
                sizing="md"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <Label htmlFor="nameEn" value="სახელი (ინგლისურად)" />
              <TextInput
                id="nameEn"
                type="text"
                sizing="md"
                value={nameEn}
                onChange={(e) => setNameEn(e.target.value)}
              />
            </div>
            <div>
              <Label htmlFor="order" value="რიგი" />
              <TextInput
                id="order"
                type="number"
                sizing="md"
                value={order}
                onChange={(e) => setOrder(Number(e.target.value))}
              />
            </div>
            <HtmlEditor
              onChange={setDescriptionHTML}
              initialHtml={descriptionHTML}
            />
            <HtmlEditor
              onChange={setDescriptionHTMLEn}
              initialHtml={descriptionHTMLEn}
            />
            <div>
              <Label htmlFor="file" value="ატვირთე სურათი" />
              <FileInput
                id="file"
                helperText={service.imageUrl ? service.imageUrl : "სურათი"}
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>
        {errorMessages &&
          errorMessages.map((message) => (
            <h3 className="text-main-red font-bold">{message}</h3>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="main-red"
          onClick={() => setOpenModal(false)}
          disabled={buttonsDisabled}
        >
          დახურვა
        </Button>
        <Button color="gray" onClick={handleSave} disabled={buttonsDisabled}>
          შენახვა
        </Button>
        <Button
          color="warning"
          onClick={() => handleDelete(service.id)}
          disabled={buttonsDisabled}
        >
          წაშლა
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditServiceModal;
