import React, { useEffect, useState } from "react";
import { AboutPhoto } from "../../../interfaces/AboutPhoto";
import {
  collection,
  doc,
  getDocs,
  getDoc,
  updateDoc,
  QueryDocumentSnapshot,
  DocumentData,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { db } from "../../../firebase/firebaseConfig";
import HeaderSpace from "../../../components/Shared/HeaderSpace/HeaderSpace";
import Sidebar from "../../../components/Shared/Sidebar/Sidebar";
import { Button, Table, TextInput, FileInput, Label } from "flowbite-react";
import AdminAboutPhotosTableRow from "./Table/AdminAboutPhotosTableRow";
import HtmlEditor from "../../../components/HtmlEditor/HtmlEditor";
import AddAboutPhotoModal from "./Modals/AddAboutPhotoModal";

function AdminAboutPhotos() {
  const [aboutPhotos, setAboutPhotos] = useState<AboutPhoto[]>([]);
  const [aboutData, setAboutData] = useState<DocumentData | null>(null); // State for about data
  const [openModal, setOpenModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleEn, setTitleEn] = useState(""); // English title
  const [descriptionEn, setDescriptionEn] = useState(""); // English description
  const [images, setImages] = useState<string[]>([]); // State for images
  const [file, setFile] = useState<File | null>(null); // State for the file to be uploaded
  const [uploading, setUploading] = useState(false); // State to handle upload status
  const [stats, setStats] = useState({
    students: "",
    partners: "",
    experience: "",
    studentsEn: "", // English version
    partnersEn: "", // English version
    experienceEn: "", // English version
  }); // State for stats

  useEffect(() => {
    const fetchData = async () => {
      // Fetch about photos
      const querySnapshot = await getDocs(collection(db, "about"));
      const items = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as AboutPhoto)
      );
      setAboutPhotos(items);

      // Fetch about data from pages collection
      const aboutDocRef = doc(db, "pages", "about");
      const aboutDocSnapshot = await getDoc(aboutDocRef);
      if (aboutDocSnapshot.exists()) {
        const data = aboutDocSnapshot.data();
        setAboutData(data);
        setTitle(data.title || "");
        setDescription(data.description || "");
        setTitleEn(data.titleEn || ""); // Set English title
        setDescriptionEn(data.descriptionEn || ""); // Set English description
        setImages(data.images || []);
        setStats({
          students: data.stats?.students || "",
          partners: data.stats?.partners || "",
          experience: data.stats?.experience || "",
          studentsEn: data.stats?.studentsEn || "", // English version
          partnersEn: data.stats?.partnersEn || "", // English version
          experienceEn: data.stats?.experienceEn || "", // English version
        });
      }
    };

    fetchData();
  }, []);

  // Handle file input change
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  // Handle file upload to Firebase Storage
  const handleUpload = async () => {
    if (!file) return;

    setUploading(true);

    try {
      const storage = getStorage();
      const storageRef = ref(storage, `images/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadUrl = await getDownloadURL(storageRef);

      // Update the images array with the new image URL
      const updatedImages = [...images, downloadUrl];
      setImages(updatedImages);

      // Update the Firestore document with the new images array
      if (aboutData) {
        const aboutDocRef = doc(db, "pages", "about");
        await updateDoc(aboutDocRef, {
          images: updatedImages,
        });
      }

      alert("Image uploaded successfully!");
    } catch (error) {
      console.error("Error uploading file: ", error);
    } finally {
      setUploading(false);
      setFile(null); // Clear the file input after upload
    }
  };

  // Handle image deletion
  const handleDeleteImage = async (index: number) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);

    // Update Firestore with the new images array
    if (aboutData) {
      const aboutDocRef = doc(db, "pages", "about");
      await updateDoc(aboutDocRef, { images: updatedImages });
    }
    alert("Image deleted successfully!");
  };

  // Handle stats input change
  const handleStatsChange = (key: keyof typeof stats, value: string) => {
    setStats({ ...stats, [key]: value });
  };

  const handleSave = async () => {
    if (!aboutData) return;

    // Update the about document in Firestore
    const aboutDocRef = doc(db, "pages", "about");
    await updateDoc(aboutDocRef, {
      title,
      description,
      titleEn, // English title
      descriptionEn, // English description
      images,
      stats, // Include updated stats in Firestore update
    });
    setIsEditing(false);
    alert("Information updated successfully!");
  };

  return (
    <>
      <HeaderSpace className="bg-white h-20 lg:h-36" />
      <div className="flex flex-col lg:flex-row">
        <Sidebar />
        <div className="flex-1 py-12">
          <div className="w-10/12 mx-auto flex flex-col gap-8">
            {/* Title and Description Editing Section */}
            <div className="flex flex-col lg:flex-row justify-between gap-4">
              <h2 className="text-main-red font-bold text-3xl text-center lg:text-left lg:text-5xl">
                About Us
              </h2>
            </div>

            {/* About Data Editing Section */}
            <div className="bg-white shadow rounded p-4">
              <h3 className="text-main-red font-bold text-xl lg:text-3xl mb-4">
                Page Information
              </h3>
              {isEditing ? (
                <>
                  <div className="py-4">
                    <div className="flex flex-col gap-1">
                      <label className="font-bold text-lg mb-2">Title</label>
                      <HtmlEditor
                        initialHtml={title}
                        onChange={(value: string) => setTitle(value)}
                      />
                    </div>
                    <div className="flex flex-col gap-1">
                      <label className="font-bold text-lg mb-2">
                        Title (English)
                      </label>
                      <HtmlEditor
                        initialHtml={titleEn}
                        onChange={(value: string) => setTitleEn(value)}
                      />
                    </div>
                    <div className="flex flex-col gap-1">
                      <label className="font-bold text-lg mt-4 mb-2">
                        Description
                      </label>
                      <HtmlEditor
                        initialHtml={description}
                        onChange={(value: string) => setDescription(value)}
                      />
                    </div>
                    <div className="flex flex-col gap-1">
                      <label className="font-bold text-lg mt-4 mb-2">
                        Description (English)
                      </label>
                      <HtmlEditor
                        initialHtml={descriptionEn}
                        onChange={(value: string) => setDescriptionEn(value)}
                      />
                    </div>
                  </div>
                  {/* Stats Editing Section */}
                  <div className="py-4">
                    <div className="flex flex-col gap-2">
                      <label className="font-bold text-lg mt-4 mb-2">
                        Statistics
                      </label>
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold">Students</label>
                          <TextInput
                            value={stats.students}
                            onChange={(e) =>
                              handleStatsChange("students", e.target.value)
                            }
                            placeholder="Number of Students"
                          />
                        </div>
                        <div className="flex flex-col gap-1">
                          <label className="font-bold">
                            Students (English)
                          </label>
                          <TextInput
                            value={stats.studentsEn}
                            onChange={(e) =>
                              handleStatsChange("studentsEn", e.target.value)
                            }
                            placeholder="Number of Students (English)"
                          />
                        </div>
                        <div className="flex flex-col gap-1">
                          <label className="font-bold">Partners</label>
                          <TextInput
                            value={stats.partners}
                            onChange={(e) =>
                              handleStatsChange("partners", e.target.value)
                            }
                            placeholder="Number of Partners"
                          />
                        </div>
                        <div className="flex flex-col gap-1">
                          <label className="font-bold">
                            Partners (English)
                          </label>
                          <TextInput
                            value={stats.partnersEn}
                            onChange={(e) =>
                              handleStatsChange("partnersEn", e.target.value)
                            }
                            placeholder="Number of Partners (English)"
                          />
                        </div>
                        <div className="flex flex-col gap-1">
                          <label className="font-bold">Experience</label>
                          <TextInput
                            value={stats.experience}
                            onChange={(e) =>
                              handleStatsChange("experience", e.target.value)
                            }
                            placeholder="Years of Experience"
                          />
                        </div>
                        <div className="flex flex-col gap-1">
                          <label className="font-bold">
                            Experience (English)
                          </label>
                          <TextInput
                            value={stats.experienceEn}
                            onChange={(e) =>
                              handleStatsChange("experienceEn", e.target.value)
                            }
                            placeholder="Years of Experience (English)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Image Editing Section */}
                  <div className="py-4">
                    <label className="font-bold text-lg mt-4 mb-2">Images</label>
                    <div className="flex flex-col gap-2">
                      {images.map((image, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center gap-4"
                        >
                          <div className="flex gap-2 items-center">
                            <img
                              src={image}
                              alt={`about-image-${index}`}
                              className="w-32 h-32 object-cover rounded"
                            />
                            <span>{image}</span>
                          </div>
                          <Button
                            onClick={() => handleDeleteImage(index)}
                            className="bg-red-500 text-white"
                          >
                            Delete
                          </Button>
                        </div>
                      ))}
                    </div>

                    {/* File Input for Image Upload */}
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="file" value="Upload Image" />
                      </div>
                      <FileInput
                        id="file"
                        helperText={file ? file.name : "Select an image"}
                        onChange={handleFileChange}
                      />
                      <Button
                        onClick={handleUpload}
                        disabled={!file || uploading}
                        className="mt-2 bg-main-red text-white"
                      >
                        {uploading ? "Uploading..." : "Upload Image"}
                      </Button>
                    </div>
                  </div>

                  <div className="flex gap-4 mt-4">
                    <Button
                      onClick={handleSave}
                      className="bg-main-red text-white"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() => setIsEditing(false)}
                      className="bg-gray-400 text-white"
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  {/* Render the title and description as HTML */}
                  <div
                    dangerouslySetInnerHTML={{ __html: title }}
                    className="text-lg font-bold mb-2"
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: description }}
                    className="mb-4"
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: titleEn }}
                    className="text-lg font-bold mb-2"
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: descriptionEn }}
                    className="mb-4"
                  />

                  {/* Render stats */}
                  <div className="mt-4">
                    <h4 className="text-xl py-2 font-bold">Statistics</h4>
                    <p>Students: {stats.students}</p>
                    <p>Partners: {stats.partners}</p>
                    <p>Experience: {stats.experience}</p>
                    <p>Students (English): {stats.studentsEn}</p>
                    <p>Partners (English): {stats.partnersEn}</p>
                    <p>Experience (English): {stats.experienceEn}</p>
                  </div>

                  {/* Render images */}
                  <div className="mt-4">
                    <h4 className="text-xl py-2 font-bold">Images</h4>
                    <div className="flex flex-wrap gap-4 mt-2">
                      {images.map((image, index) => (
                        <div key={index} className="flex flex-col items-center">
                          <img
                            src={image}
                            alt={`about-${index}`}
                            className="w-32 h-32 object-cover rounded"
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  <Button
                    onClick={() => setIsEditing(true)}
                    className="mt-4 bg-main-red text-white"
                  >
                    Edit
                  </Button>
                </>
              )}
            </div>

            <div>
              <div className="flex flex-col lg:flex-row justify-between gap-4">
                <h2 className="text-main-red font-bold text-xl text-center lg:text-left lg:text-3xl">
                  Photos
                </h2>
                <Button
                  className="flex items-center gap-2 bg-main-red"
                  color={"main-red"}
                  onClick={() => setOpenModal(!openModal)}
                >
                  Add Photo {" +"}
                </Button>
              </div>

              {/* About Photos Table */}
              <div className="overflow-x-auto mt-8">
                <Table striped>
                  <Table.Head>
                    <Table.HeadCell>Image</Table.HeadCell>
                    <Table.HeadCell>Order</Table.HeadCell>
                    <Table.HeadCell>
                      <span className="sr-only">Edit</span>
                    </Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="divide-y">
                    {aboutPhotos &&
                      aboutPhotos.map((photo) => (
                        <AdminAboutPhotosTableRow
                          id={photo.id}
                          imageUrl={photo.imageUrl}
                          order={photo.order}
                          key={photo.id}
                        />
                      ))}
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddAboutPhotoModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
}

export default AdminAboutPhotos;
