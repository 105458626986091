import {
  Button,
  FileInput,
  Label,
  Modal,
  Table,
  Textarea,
  TextInput,
} from "flowbite-react";
import React, { useState } from "react";
import { Employee } from "../../../../interfaces/Employee";
import EditTeamModal from "../Modals/EditTeamModal";


function AdminTeamTableRow(employee: Employee) {
  const [openModal, setOpenModal] = useState(false);

  

  return (
    <>
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
        <Table.Cell>
          <img
            src={employee.imageUrl}
            alt="empl"
            className="w-16 max-h-16 rounded-full"
          />
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {employee.name}
        </Table.Cell>
        <Table.Cell>{employee.roles.map((role) => role + ",")}</Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {employee.order}
        </Table.Cell>
        <Table.Cell>
          <a
            onClick={() => setOpenModal(true)}
            className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 hover:cursor-pointer"
          >
            შევცლა
          </a>
        </Table.Cell>
      </Table.Row>
      <EditTeamModal
      employee={employee}
      openModal={openModal}
      setOpenModal={setOpenModal}
      />
    </>
  );
}

export default AdminTeamTableRow;
