import React, { useEffect, useState } from "react";
import HeaderSpace from "../../../components/Shared/HeaderSpace/HeaderSpace";
import { Button, Table } from "flowbite-react";
import Sidebar from "../../../components/Shared/Sidebar/Sidebar";
import {
  collection,
  DocumentData,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { db } from "../../../firebase/firebaseConfig";
import { FarePhoto } from "../../../interfaces/FaresPhoto";
import AddFarePhotoModal from "./Modals/AddFarePhotoModal";
import AdminFarePhotoTableRow from "./Table/AdminFarePhotoTableRow";

function AdminFarePhotos() {
  const [farePhotos, setFarePhotos] = useState<FarePhoto[]>([]);
  const [openModal, setOpenModal] = useState(false);

  const fetchData = async () => {
    const querySnapshot = await getDocs(collection(db, "faresPhotos"));
    const items = querySnapshot.docs.map(
      (doc: QueryDocumentSnapshot<DocumentData>) =>
        ({
          id: doc.id,
          ...doc.data(),
        } as FarePhoto)
    );
    setFarePhotos(items);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <HeaderSpace className="bg-white h-20 lg:h-36" />
      <div className="flex flex-col lg:flex-row">
        <Sidebar />
        <div className="flex-1 py-12">
          <div className="w-10/12 mx-auto flex flex-col gap-8">
            <div className="flex flex-col lg:flex-row justify-between gap-4">
              <h2 className="text-main-red font-bold text-3xl text-center lg:text-left lg:text-5xl">
                ღონისძიების ფოტოები
              </h2>
              <Button
                className="flex items-center gap-2 bg-main-red"
                color={"main-red"}
                onClick={() => setOpenModal(!openModal)}
              >
                დამატება{" +" }
              </Button>
            </div>
            <div className="overflow-x-auto">
              <Table striped>
                <Table.Head>
                  <Table.HeadCell>სურათი</Table.HeadCell>
                  <Table.HeadCell>რაოდენობა</Table.HeadCell>
                  <Table.HeadCell>
                    <span className="sr-only">Edit</span>
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {farePhotos.sort((a,b) => a.order - b.order).map((photo) => (
                    <AdminFarePhotoTableRow
                      key={photo.id}
                      id={photo.id}
                      imageUrl={photo.imageUrl}
                      order={photo.order}
                    />
                  ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <AddFarePhotoModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
}

export default AdminFarePhotos;
