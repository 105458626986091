import React, { useEffect, useState } from "react";
import Container from "../../Shared/Container/Container";
import { Button, Spinner } from "flowbite-react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Blog } from "../../../interfaces/Blog";
import {
  collection,
  DocumentData,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { db } from "../../../firebase/firebaseConfig";
import { HiOutlineArrowRight } from "react-icons/hi";

function MainBlog() {
  const [blogs, setBlogs] = useState<Blog[]>();
  const { t, i18n } = useTranslation(); // Translation hook with language detection

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "blogs"));
      const items = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Blog)
      );
      setBlogs(items);
    };

    fetchData();
  }, []);

  if (!blogs) {
    return <Spinner />;
  }
  return (
    <div
      className="relative bg-repeat py-12"
      style={{
        backgroundImage: `url('/images/backgrounds/background-dots-01.png')`,
        backgroundSize: "150px 150px",
      }}
    >
      <Container className="mb-8">
        <h3 className="text-2xl lg:text-4xl font-bold text-main-blue text-center">
          {t('blog')}
        </h3>
      </Container>
      <Container className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        {blogs  
          .sort(
            (a, b) =>
              new Date(a.createDate).getTime() -
              new Date(b.createDate).getTime()
          )
          .slice(0, 3)
          .map((blog) => (
            <div
              key={blog.id}
              className="bg-white border-[1px] border-gray-200 rounded-lg p-8 min-h-96 hover:bg-gray-200 hover:cursor-pointer duration-100"
              onClick={() => navigate(`blog/${blog.id}`)}
            >
              <div className="font-bold text-xl lg:text-2xl text-main-blue">
                {i18n.language === 'en' ? blog.titleEn : blog.title}
              </div>
              <div className="my-2 text-sm font-bold text-gray-500">
                {new Date(blog.createDate).toLocaleDateString("ka-GE", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </div>
              <div className="text-[16px] mt-4">{i18n.language === 'en' ? blog.descriptionEn : blog.description}</div>
            </div>
          ))}
      </Container>
      <Container>
        <div className="w-full flex justify-end">
          <Link to={"/blog"}>
            <Button
              color={"main-red"}
              className="text-main-red bg-transparent flex flex-col justify-center items-center font-bold hover:bg-main-red hover:text-white"
            >
              {t("learnMore")} {/* Translation for "გაიგე მეტი" */}
              <HiOutlineArrowRight className="ml-2 h-5 w-5" />
            </Button>
          </Link>
        </div>
      </Container>
    </div>
  );
}

export default MainBlog;
