import { Table } from "flowbite-react";
import React, { useState } from "react";
import { Blog } from "../../../../interfaces/Blog";
import EditBlogModal from "../Modals/EditBlogModal";

function AdminBlogTableRow(blog: Blog) {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {blog.createDate}
        </Table.Cell>
        <Table.Cell>
          <img src={blog.imageUrl} alt="empl" className="w-16 max-h-16" />
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {blog.title}
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {/* <div dangerouslySetInnerHTML={{__html:student.descriptionHTML}} /> */}
          {blog.bodyHTML.slice(0, 30)}
        </Table.Cell>
        <Table.Cell>
          <a
            onClick={() => setOpenModal(true)}
            className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 hover:cursor-pointer"
          >
            შევცლა
          </a>
        </Table.Cell>
      </Table.Row>
      <EditBlogModal
        blog={blog}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  );
}

export default AdminBlogTableRow;
