import React, { useState } from "react";
import { Button, FileInput, Label, Modal, TextInput } from "flowbite-react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../../firebase/firebaseConfig";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { object, string, number } from "yup";
import { useNavigate } from "react-router";
import HtmlEditor from "../../../../components/HtmlEditor/HtmlEditor";

interface AddEnglishCenterModalProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddEnglishCenterModal({ openModal, setOpenModal }: AddEnglishCenterModalProps) {
  const [name, setName] = useState("");
  const [nameEn, setNameEn] = useState(""); // English name
  const [descriptionHTML, setDescriptionHTML] = useState("");
  const [descriptionHTMLEn, setDescriptionHTMLEn] = useState(""); // English description
  const [order, setOrder] = useState<number | undefined>(undefined);
  const [file, setFile] = useState<File | null>(null);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const navigate = useNavigate();

  let serviceSchema = object({
    name: string().required("სახელი აუცილებელია!"),
    nameEn: string().required("English name is required!"),
    order: number().required("რიგი აუცილებელია!"),
    imageUrl: string().required("სურათი აუცილებელია!"),
    descriptionHTML: string().required("აღწერა აუცილებელია!"),
    descriptionHTMLEn: string().required("English description is required!"),
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleSave = async () => {
    try {
      let imageUrl = "";

      if (file) {
        const storage = getStorage();
        const storageRef = ref(storage, `images/${file.name}`);
        await uploadBytes(storageRef, file);
        imageUrl = await getDownloadURL(storageRef);
      }

      await serviceSchema.validate({
        name,
        nameEn,
        order,
        imageUrl,
        descriptionHTML,
        descriptionHTMLEn,
      });

      const newService = {
        name,
        nameEn,
        order: order || 0,
        imageUrl,
        descriptionHTML,
        descriptionHTMLEn,
      };

      const englishCenterCollectionRef = collection(db, "englishCenter");
      await addDoc(englishCenterCollectionRef, newService);

      setOpenModal(false);
      navigate(0);
    } catch (e: any) {
      console.error(e);
      setErrorMessages(e.errors);
    }
  };

  return (
    <Modal
      dismissible
      show={openModal}
      onClose={() => setOpenModal(false)}
      className="font-mtavvruli"
    >
      <Modal.Header className="text-main-blue font-bold">დაამატე სერვისი</Modal.Header>
      <Modal.Body>
        <div className="space-y-6 w-full">
          <div className="flex flex-col gap-4">
            <div>
              <Label htmlFor="name" value="სახელი" />
              <TextInput
                id="name"
                type="text"
                sizing="md"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <Label htmlFor="nameEn" value="სახელი (ინგლისურად)" />
              <TextInput
                id="nameEn"
                type="text"
                sizing="md"
                value={nameEn}
                onChange={(e) => setNameEn(e.target.value)}
              />
            </div>
            <div>
              <Label htmlFor="order" value="რიგი" />
              <TextInput
                id="order"
                type="number"
                sizing="md"
                value={order || ""}
                onChange={(e) => setOrder(Number(e.target.value))}
              />
            </div>
            <HtmlEditor
              onChange={setDescriptionHTML}
              initialHtml={descriptionHTML}
            />
            <HtmlEditor
              onChange={setDescriptionHTMLEn}
              initialHtml={descriptionHTMLEn}
            />
            <div>
              <Label htmlFor="file" value="ატვირთე სურათი" />
              <FileInput
                id="file"
                helperText="სურათი"
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>
        {errorMessages &&
          errorMessages.map((message) => (
            <h3 className="text-main-red font-bold">{message}</h3>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="main-red"
          onClick={() => setOpenModal(false)}
          disabled={buttonsDisabled}
        >
          დახურვა
        </Button>
        <Button color="gray" onClick={handleSave} disabled={buttonsDisabled}>
          დამატება
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddEnglishCenterModal;
