import { Button, Label, Modal, TextInput } from "flowbite-react";
import React, { useState } from "react";
import { FAQ } from "../../../../interfaces/FAQ";
import { useNavigate } from "react-router";
import { number, object, string } from "yup";
import { deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase/firebaseConfig";
import HtmlEditor from "../../../../components/HtmlEditor/HtmlEditor";

interface EditFaqModal {
  FAQ: FAQ;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function EditFaqModal({ FAQ, openModal, setOpenModal }: EditFaqModal) {
  // State for both Georgian and English content
  const [question, setQuestion] = useState(FAQ.question);
  const [questionEn, setQuestionEn] = useState(FAQ.questionEn); // English question
  const [answerHTML, setAnswerHTML] = useState(FAQ.answerHTML);
  const [answerHTMLEn, setAnswerHTMLEn] = useState(FAQ.answerHTMLEn); // English answer
  const [order, setOrder] = useState(FAQ.order);

  const [errorMessages, setErrorMessages] = useState<string[]>();

  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const navigate = useNavigate();

  let faqSchema = object({
    question: string().required("შეკითხვა აუცილებელია!"),
    questionEn: string().required("Question (English) is required!"),
    answerHTML: string().required("პასუხი აუცილებელია!"),
    answerHTMLEn: string().required("Answer (English) is required!"),
    order: number()
      .min(1, "რიგის ნომერი 1-ზე ნაკლები რიცხვი არ შეიძლება!")
      .required(),
  });

  const handleSave = async () => {
    if (!FAQ) {
      setErrorMessages(["No FAQ selected for editing."]);
      return;
    }

    try {
      await faqSchema.validate({
        question,
        questionEn,
        answerHTML,
        answerHTMLEn,
        order,
      });

      const updatedFaq: FAQ = {
        id: FAQ.id,
        question,
        questionEn,
        answerHTML,
        answerHTMLEn,
        order,
      };

      const faqDocRef = doc(db, "faq", updatedFaq.id);
      await setDoc(faqDocRef, updatedFaq, { merge: true });
      await updateDoc(faqDocRef, updatedFaq as { [x: string]: any });

      setOpenModal(false);
      navigate(0);
    } catch (e: any) {
      let messages = e.errors;
      setErrorMessages(messages);
    }
  };

  const handleDelete = async (faqId: string) => {
    setButtonsDisabled(true);
    try {
      const faqDocRef = doc(db, "faq", faqId);
      await deleteDoc(faqDocRef);
      navigate(0); // Refresh the page or update the state to reflect changes
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
    setButtonsDisabled(false);
  };

  return (
    <>
      <Modal
        dismissible
        show={openModal}
        onClose={() => setOpenModal(false)}
        className="font-mtavvruli"
      >
        <Modal.Header className="text-main-blue font-bold">
          შეცვალე <span className="text-main-red">{FAQ.question}</span>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6 w-full">
            <div className="flex flex-col gap-4">
              {/* Georgian Question */}
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="question" value="ჩაწერეთ შეკითხვა (Georgian)" />
                </div>
                <TextInput
                  id="question"
                  type="text"
                  sizing="md"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
              </div>

              {/* English Question */}
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="questionEn" value="Enter Question (English)" />
                </div>
                <TextInput
                  id="questionEn"
                  type="text"
                  sizing="md"
                  value={questionEn}
                  onChange={(e) => setQuestionEn(e.target.value)}
                />
              </div>

              {/* Georgian Answer */}
              <HtmlEditor initialHtml={answerHTML} onChange={setAnswerHTML} />

              {/* English Answer */}
              <HtmlEditor initialHtml={answerHTMLEn} onChange={setAnswerHTMLEn} />

              {/* Order */}
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="order" value="რიგის ნომერი(მერამდენე გამოჩნდეს)" />
                </div>
                <TextInput
                  id="order"
                  type="text"
                  sizing="md"
                  value={order}
                  onChange={(e) => setOrder(Number(e.target.value))}
                />
              </div>
            </div>
          </div>
          {errorMessages &&
            errorMessages.map((message) => (
              <h3 className="text-main-red font-bold" key={message}>
                {message}
              </h3>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="main-red"
            onClick={() => setOpenModal(false)}
            disabled={buttonsDisabled}
          >
            დახურვა
          </Button>
          <Button color="gray" onClick={handleSave} disabled={buttonsDisabled}>
            შენახვა
          </Button>
          <Button
            color={"warning"}
            onClick={() => handleDelete(FAQ.id)}
            disabled={buttonsDisabled}
          >
            წაშლა
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditFaqModal;
