import "./App.css";
import { Flowbite } from "flowbite-react";
import { Outlet } from "react-router";
import lightTheme from "./themes";
import Navbar from "./components/Shared/Navbar/Navbar";
import ContactUs from "./components/Shared/ContactUs/ContactUs";
import Footer from "./components/Shared/Footer/Footer";
import { ToastContainer } from "react-toastify";
import { ScrollRestoration } from "react-router-dom";
import { I18nextProvider, useTranslation } from "react-i18next";
// Import the i18n configuration
import './i18n'; 

function App() {
  const { i18n } = useTranslation();
  return (
      <Flowbite theme={{ theme: lightTheme }}>
        <ScrollRestoration />
        <div className="font-mtavvruli bg-main-background">
          <Navbar />
          <Outlet />
          <ContactUs />
          <Footer />
          {/* <Footer /> */}
        </div>
        <ToastContainer />
      </Flowbite>
  );
}

export default App;
