import React from "react";
import { Service } from "../../interfaces/Service";
import { Button, Modal } from "flowbite-react";

interface ISquareServicePreviewModal {
  service: Service;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function SquareServicePreviewModal({
  service,
  openModal,
  setOpenModal,
}: ISquareServicePreviewModal) {
  return (
    <Modal
      show={openModal}
      onClose={() => setOpenModal(false)}
      className="font-mrgvlovani"
      size={"5xl"}
    >
      <Modal.Header>
        <div className="font-bold text-sm">{service.name}</div>
      </Modal.Header>
      <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4">
        <div
          dangerouslySetInnerHTML={{ __html: service.descriptionHTML }}
          className="p-8 text-sm overflow-y-auto max-h-56 lg:max-h-72"
        />
        <div>
          <img
            src={service.imageUrl}
            alt="img"
            className="h-full max-h-72 w-full object-cover"
          />
        </div>
      </div>
      <Modal.Footer>
        <Button onClick={() => setOpenModal(false)}>გასაგებია</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SquareServicePreviewModal;
