import React, { useState } from "react";
import { Mail } from "../../../../interfaces/Mail";
import { Table } from "flowbite-react";
import ViewMailModal from "../Modals/ViewMailModal";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase/firebaseConfig";

interface AdminMailTableRowProps extends Mail {
  onReplyClick: () => void; // Add onReplyClick prop type
}

const AdminMailTableRow: React.FC<AdminMailTableRowProps> = ({
  id,
  name,
  email,
  message,
  phoneNumber,
  dateCreated,
  replied,
  viewed,
  onReplyClick, // Destructure the onReplyClick prop
}) => {
  const [openModal, setOpenModal] = useState(false);

  const handleViewClick = async () => {
    setOpenModal(true);

    if (!viewed) {
      const docRef = doc(db, "mails", id);
      try {
        await updateDoc(docRef, { viewed: true });
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
  };

  return (
    <>
      {/* Table row displaying mail details */}
      <Table.Row
        className={`${
          viewed ? "!bg-white" : "!bg-gray-200"
        } dark:border-gray-700 dark:bg-gray-800`}
      >
        <Table.Cell>{new Date(dateCreated).toLocaleDateString()}</Table.Cell>
        <Table.Cell>{name}</Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {email}
        </Table.Cell>
        <Table.Cell>{phoneNumber}</Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {message.slice(0, 20) + "..."}
        </Table.Cell>
        <Table.Cell
          className={`whitespace-nowrap font-medium text-gray-900 dark:text-white ${
            replied ? "text-green-600" : "text-red-500"
          }`}
        >
          {replied ? "პასუხი გაცემულია" : "ელოდება პასუხს"}
        </Table.Cell>
        <Table.Cell>
          <a
            onClick={handleViewClick}
            className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 hover:cursor-pointer"
          >
            ნახვა
          </a>
        </Table.Cell>
      </Table.Row>

      {/* Modal for viewing mail details */}
      <ViewMailModal
        mail={{ id, name, email, message, phoneNumber, dateCreated }}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

export default AdminMailTableRow;
