import { Table } from "flowbite-react";
import React, { useState } from "react";
import { Certificate } from "../../../../interfaces/Certificate";
import EditCertificateModal from "../Modals/EditCertificateModal";

interface AdminCertificatesTableRowProps {
  certificate: Certificate;
}

const AdminCertificatesTableRow: React.FC<AdminCertificatesTableRowProps> = ({ certificate }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
        <Table.Cell>
          <img src={certificate.imageUrl} alt={certificate.name} className="w-16 max-h-16" />
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {certificate.name}
        </Table.Cell>
        <Table.Cell>
          <a
            onClick={() => setOpenModal(true)}
            className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 hover:cursor-pointer"
          >
            შევცლა
          </a>
        </Table.Cell>
      </Table.Row>
      <EditCertificateModal
        certificate={certificate}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

export default AdminCertificatesTableRow;
