import React, { useEffect, useState } from "react";
import HeaderSpace from "../../../components/Shared/HeaderSpace/HeaderSpace";
import Sidebar from "../../../components/Shared/Sidebar/Sidebar";
import { Table, Button, Pagination } from "flowbite-react";
import {
  collection,
  DocumentData,
  getDocs,
  QueryDocumentSnapshot,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../../firebase/firebaseConfig";
import { Service } from "../../../interfaces/Service";
import AdminEnglishCenterTableRow from "./Table/AdminEnglishCenterTableRow";
import AddEnglishCenterModal from "./Modals/AddEnglishCenterModal";

function AdminEnglishCenter() {
  const [services, setServices] = useState<Service[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [addServiceModalOpen, setAddServiceModalOpen] = useState(false);

  const fetchServices = async () => {
    const querySnapshot = await getDocs(collection(db, "englishCenter"));
    const items = querySnapshot.docs.map(
      (doc: QueryDocumentSnapshot<DocumentData>) =>
        ({
          id: doc.id,
          ...doc.data(),
        } as Service)
    );
    setServices(items);
  };

  useEffect(() => {
    fetchServices();
  }, []);

  // Pagination calculations
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentServices = services
    .sort((a, b) => a.order - b.order)
    .slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(services.length / itemsPerPage);

  const handlePageChange = (page: number) => setCurrentPage(page);

  const handleEditClick = (service: Service) => setSelectedService(service);

  const handleDeleteClick = async (serviceId: string) => {
    await deleteDoc(doc(db, "englishCenter", serviceId));
    fetchServices();
  };

  return (
    <>
      <HeaderSpace className="bg-white h-20 lg:h-36" />
      <div className="flex flex-col lg:flex-row">
        <Sidebar />
        <div className="flex-1 py-12">
          <div className="w-10/12 mx-auto flex flex-col gap-8">
            <div className="flex flex-col lg:flex-row justify-between gap-4">
              <h2 className="text-main-red font-bold text-2xl text-center lg:text-left lg:text-4xl">
                სერვისების მართვა
              </h2>
              <Button onClick={() => setAddServiceModalOpen(!addServiceModalOpen)} className="bg-main-red">
                დამატება 
              </Button>
            </div>
            <div className="overflow-x-auto">
              <Table striped>
                <Table.Head>
                  <Table.HeadCell>სურათი</Table.HeadCell>
                  <Table.HeadCell>სახელი</Table.HeadCell>
                  <Table.HeadCell>დალაგება</Table.HeadCell>
                  <Table.HeadCell>
                    <span className="sr-only">შეცვლა</span>
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {currentServices.map((service) => (
                    <AdminEnglishCenterTableRow
                      key={service.id}
                      service={service}
                      onEditClick={() => handleEditClick(service)}
                      onDeleteClick={() => handleDeleteClick(service.id)}
                    />
                  ))}
                </Table.Body>
              </Table>
            </div>

            {/* Pagination Controls */}
            <div className="flex justify-center mt-4">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                showIcons
                previousLabel="წინა"
                nextLabel="შემდეგი"
                className="pagination"
              />
            </div>
          </div>
        </div>
      </div>
      <AddEnglishCenterModal
        openModal={addServiceModalOpen}
        setOpenModal={setAddServiceModalOpen}
      />
    </>
  );
}

export default AdminEnglishCenter;
