import { useState, FC } from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Spinner } from "flowbite-react";

// Utility function to check if the userToken cookie exists and has the correct value
const isUserAuthenticated = () => {
  const userToken = Cookies.get("userToken");
  return userToken === "ghost-cookies";
};

export const scrollToContact = () => {
  const element = document.getElementById("contact-us-section");
  element?.scrollIntoView({
    behavior: "smooth",
  });
};

// Define the props type
interface PrivateRouteProps {
  Component: React.ReactElement;
}

const IsAuthenticatedPrivateRoute: FC<PrivateRouteProps> = ({ Component }) => {
  const isAuthenticated = isUserAuthenticated();

  if (!Component) {
    return <Spinner />;
  }

  return isAuthenticated ? Component : <Navigate to="/login" />;
};

export default IsAuthenticatedPrivateRoute;
