import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Container from "../../components/Shared/Container/Container";
import HeaderSpace from "../../components/Shared/HeaderSpace/HeaderSpace";
import { Carousel } from "flowbite-react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

interface FarePhoto {
  id: string;
  imageUrl: string;
  order: number;
}

function Fares() {
  const { t } = useTranslation();
  const [faresPhotos, setFaresPhotos] = useState<FarePhoto[]>([]);

  // Fetch faresPhotos from Firestore
  const fetchFaresPhotos = async () => {
    const querySnapshot = await getDocs(collection(db, "faresPhotos"));
    const photos = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as FarePhoto[];
    setFaresPhotos(photos);
  };

  useEffect(() => {
    fetchFaresPhotos();
  }, []);

  return (
    <div
      className="relative bg-repeat"
      style={{
        backgroundImage: `url('/images/backgrounds/background-dots-01.png')`,
        backgroundSize: "150px 150px",
      }}
    >
      <div className="flex flex-col">
        <HeaderSpace />
        <Container className="py-8 lg:py-16 flex flex-col gap-8">
          <div className="text-xl lg:text-4xl font-bold text-main-red text-center">
            {t("fares")}
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 min-h-80">
            <Carousel>
              {faresPhotos.sort((a,b) => a.order - b.order).slice(0, 5).map((photo, idx) => (
                <Zoom>
                  <img
                    key={photo.id}
                    src={photo.imageUrl}
                    alt={`Fare ${photo.id}`}
                    className={`h-full w-full object-cover max-h-80 object-${idx === 0 ? 'center' : 'top'}`}
                  />
                </Zoom>
              ))}
            </Carousel>
            <div className="flex flex-col justify-center text-gray-900">
              <div>{t("2002wlidan")}</div>
              <br />
              <div>{t("EllTavisPart")}</div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 mt-8">
            {faresPhotos.sort((a,b) => a.order - b.order).slice(5).map((photo) => (
              <div className="max-h-32 h-full w-full">
                <Zoom >
                  <img
                    key={photo.id}
                    src={photo.imageUrl}
                    alt={`Fare ${photo.id}`}
                    className="h-full max-h-32 w-full object-cover object-center rounded-lg"
                  />
                </Zoom>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Fares;
