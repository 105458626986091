import React from "react";
import Container from "../Shared/Container/Container";

import { PiStudentBold } from "react-icons/pi";
import { FaUniversity } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa";
import { useTranslation } from "react-i18next"; // Import useTranslation

interface IStats {
  students: string;
  partners: string;
  experience: string;
}

function Stats({ students, partners, experience }: IStats) {
  const { t } = useTranslation(); // Initialize translation hook

  return (
    <div className="w-full bg-white rounded-xl">
      <Container className="grid grid-rows-3 gap-8 py-8">
        <div className="flex flex-row w-full justify-between items-center border-b-[1px] border-gray-300 pb-4">
          <div className="flex flex-col">
            <h3 className="text-main-blue text-sm font-bold">
              {t("student")} {/* Translation for "სტუდენტი" */}
            </h3>
            <h2 className="text-main-red text-4xl font-bold">{students}</h2>
          </div>
          <PiStudentBold className="h-12 w-12 text-main-blue" />
        </div>
        <div className="flex flex-row w-full justify-between items-center border-b-[1px] border-gray-300 pb-4">
          <div className="flex flex-col">
            <h3 className="text-main-blue text-sm font-bold">
              {t("partnerUniversities")} {/* Translation for "პარტნიორი უნივერსიტეტი" */}
            </h3>
            <h2 className="text-main-red text-4xl font-bold">{partners}</h2>
          </div>
          <FaUniversity className="h-12 w-12 text-main-blue" />
        </div>
        <div className="flex flex-row w-full justify-between items-center border-gray-300 pb-4">
          <div className="flex flex-col">
            <h3 className="text-main-blue text-sm font-bold">
              {t("experience")} {/* Translation for "გამოცდილება" */}
            </h3>
            <h2 className="text-main-red text-4xl font-bold">{experience}</h2>
          </div>
          <FaBriefcase className="h-12 w-12 text-main-blue" />
        </div>
      </Container>
    </div>
  );
}

export default Stats;
