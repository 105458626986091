import { Button, Label, Modal, FileInput } from "flowbite-react";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { object, string, number } from "yup";
import { setDoc, doc, collection } from "firebase/firestore";
import { db, storage } from "../../../../firebase/firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FarePhoto } from "../../../../interfaces/FaresPhoto";

interface AddFarePhotoModalProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddFarePhotoModal: React.FC<AddFarePhotoModalProps> = ({
  openModal,
  setOpenModal,
}) => {
  const [imageUrl, setImageUrl] = useState<string>("");
  const [order, setOrder] = useState<number | null>(null);
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  const farePhotoSchema = object({
    imageUrl: string().required("სურათის URL აუცილებელია!"),
    order: number()
      .required("რაოდენობა აუცილებელია!")
      .typeError("რაოდენობა უნდა იყოს ციფრი."),
  });

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploading(true);

      const photoRef = doc(collection(db, "farePhotos"));
      const storageRef = ref(storage, `farePhotos/${photoRef.id}/image`);

      try {
        await uploadBytes(storageRef, file);
        const downloadUrl = await getDownloadURL(storageRef);
        setImageUrl(downloadUrl);
      } catch (error) {
        console.error("Image upload failed:", error);
        setErrorMessages([
          "სურათის ატვირთვა ვერ მოხერხდა. გთხოვთ, ცადოთ თავიდან.",
        ]);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleSave = async () => {
    if (!imageUrl || order === null) {
      setErrorMessages(
        [
          !imageUrl ? "სურათის URL ველი სავალდებულოა." : "",
          order === null ? "რაოდენობის ველი სავალდებულოა." : "",
        ].filter(Boolean)
      );
      return;
    }

    try {
      await farePhotoSchema.validate({ imageUrl, order });

      const photoRef = doc(collection(db, "faresPhotos"));
      const newFarePhoto: FarePhoto = {
        id: photoRef.id,
        imageUrl,
        order,
      };

      await setDoc(photoRef, newFarePhoto);

      setOpenModal(false);
      navigate(0); // Refresh page or update state to reflect changes
    } catch (e: any) {
      let messages = e.errors;
      setErrorMessages(messages);
    }
  };

  return (
    <Modal
      dismissible
      show={openModal}
      onClose={() => setOpenModal(false)}
      className="font-mtavvruli"
    >
      <Modal.Header className="text-main-blue font-bold">
        ღონისძიების ფოტოს დამატება
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-6 w-full">
          <div className="flex flex-col gap-4">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="file" value="ატვირთე სურათი" />
              </div>
              <FileInput
                id="file"
                helperText={imageUrl ? imageUrl : "არჩევა ან ატვირთვა"}
                onChange={handleFileChange}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="order" value="რიგი" />
              </div>
              <input
                type="number"
                id="order"
                className="form-input w-full"
                value={order || ""}
                onChange={(e) => setOrder(Number(e.target.value))}
              />
            </div>
          </div>
        </div>
        {errorMessages &&
          errorMessages.map((message) => (
            <h3 className="text-main-red font-bold" key={message}>
              {message}
            </h3>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="main-red"
          onClick={() => setOpenModal(false)}
          disabled={buttonsDisabled || uploading}
        >
          დახურვა
        </Button>
        <Button
          color="gray"
          onClick={handleSave}
          disabled={buttonsDisabled || uploading}
        >
          {uploading ? "ატვირთვა..." : "დამატება"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddFarePhotoModal;
