import React, { useEffect, useState } from "react";
import Container from "../../components/Shared/Container/Container";
import BlogPreview from "../../components/Previews/BlogPreview";
import { Blog as BlogInterface } from "../../interfaces/Blog";
import {
  collection,
  DocumentData,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import Spinner from "../../components/Shared/Spinner/Spinner";
import { useTranslation } from "react-i18next";

function Blog() {
  const [blogs, setBlogs] = useState<BlogInterface[]>();
  const { t, i18n } = useTranslation(); // Translation hook with language detection

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "blogs"));
      const items = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as BlogInterface)
      );
      setBlogs(items);
    };

    fetchData();
  }, []);

  if (!blogs) {
    return <Spinner />;
  }

  return (
    <div
      className="relative bg-repeat"
      style={{
        backgroundImage: `url('/images/backgrounds/background-dots-01.png')`,
        backgroundSize: "150px 150px",
      }}
    >
      <div className="flex flex-col">
        <div className="relative">
          <img
            src="/images/backgrounds/background-03.jpg"
            alt="background-06.jpg"
            className="w-screen h-[50vh] lg:h-[vh] brightness-[95%] object-cover"
          />
          <div className="w-full absolute bottom-4">
            <Container>
              <h1 className="text-4xl font-bold text-main-red">{t('blog')}</h1>
            </Container>
          </div>
        </div>
      </div>
      <Container className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-12">
        {blogs &&
          blogs
            .sort((a, b) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime())
            .map((blog) => (
              <BlogPreview
                title={i18n.language === "en" ? blog.titleEn : blog.title} // Use title based on selected language
                imageUrl={blog.imageUrl}
                date={new Date(blog.createDate).toLocaleDateString()} // Format date for display
                id={blog.id}
                key={blog.id}
              />
            ))}
      </Container>
    </div>
  );
}

export default Blog;
