import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import Container from "../../../components/Shared/Container/Container";
import HeaderSpace from "../../../components/Shared/HeaderSpace/HeaderSpace";
import { Blog } from "../../../interfaces/Blog";
import { db } from "../../../firebase/firebaseConfig";
import Spinner from "../../../components/Shared/Spinner/Spinner";
import { FacebookIcon, FacebookShareButton } from "react-share";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook

function ViewBlog() {
  const { id } = useParams();
  const { i18n } = useTranslation(); // Initialize translation hook to detect current language
  const [blog, setBlog] = useState<Blog | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBlog = async () => {
      setLoading(true);
      if (id) {
        const blogRef = doc(db, "blogs", id);
        const blogSnap = await getDoc(blogRef);
        if (blogSnap.exists()) {
          setBlog(blogSnap.data() as Blog);
        } else {
          console.error("No such document!");
        }
      }
      setLoading(false);
    };

    fetchBlog();
  }, [id]);

  if (!blog) {
    return (
      <>
        <HeaderSpace className="bg-main-background" />
        <Spinner />;
      </>
    );
  }

  // Determine the title and body based on the current language
  const blogTitle = i18n.language === "en" ? blog.titleEn || blog.title : blog.title;
  const blogBody = i18n.language === "en" ? blog.bodyHTMLEn || blog.bodyHTML : blog.bodyHTML;

  return (
    <>
      <HeaderSpace className="bg-main-background h-36" />

      {loading ? (
        <Spinner />
      ) : (
        blog && (
          <div className="flex justify-center">
            <div className="flex flex-col max-w-4xl py-8">
              <div className="relative">
                <img
                  src={blog.imageUrl}
                  alt="background-06.jpg"
                  className="w-screen h-[50vh] lg:h-[vh] brightness-[95%] object-cover"
                />
              </div>
              <div className="pt-4 pb-16">
                <Container>
                  <h1 className="font-bold text-main-blue text-2xl lg:text-4xl text-center py-12">
                    {blogTitle}
                  </h1>
                  <div
                    className="content text-left"
                    dangerouslySetInnerHTML={{ __html: blogBody }}
                  ></div>
                  {/* <div>
                  <FacebookShareButton
                    url={window.location.href} // Share current URL
                  >
                    <FacebookIcon />
                  </FacebookShareButton>
                </div> */}
                </Container>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
}

export default ViewBlog;
