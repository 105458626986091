import React from 'react';
import classNames from 'classnames';

interface ContainerProps {
  children: React.ReactNode;
  className?: string;
}

const Container: React.FC<ContainerProps> = ({ children, className }) => {
  return (
    <div className={classNames('mobile_wrapper xl:max-w-7xl mx-auto', className)}>
      {children}
    </div>
  );
}

export default Container;