import React, { useEffect, useState } from "react";
import HeaderSpace from "../../../components/Shared/HeaderSpace/HeaderSpace";
import Sidebar from "../../../components/Shared/Sidebar/Sidebar";
import { Button, Table } from "flowbite-react";
import { Blog } from "../../../interfaces/Blog";
import {
  collection,
  DocumentData,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { db } from "../../../firebase/firebaseConfig";
import AdminBlogTableRow from "./Table/AdminBlogTableRow";
import EditBlogModal from "./Modals/EditBlogModal";
import AddBlogModal from "./Modals/AddBlogModal";

function AdminBlogs() {
  const [blogs, setBlogs] = useState<Blog[]>([]);

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "blogs"));
      const items = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Blog)
      );
      setBlogs(items);
    };

    fetchData();
  }, []);
  return (
    <>
      <HeaderSpace className="bg-white h-20 lg:h-36" />
      <div className="flex flex-col lg:flex-row">
        <Sidebar />
        <div className="flex-1 py-12">
          <div className="w-10/12 mx-auto flex flex-col gap-8">
            <div className="flex flex-col lg:flex-row justify-between gap-4">
              <h2 className="text-main-red font-bold text-3xl text-center lg:text-left lg:text-5xl">
                ბლოგები
              </h2>
              <Button
                className="flex items-center gap-2 bg-main-red"
                color={"main-red"}
                onClick={() => setOpenModal(!openModal)}
              >
                დამატება{" +"}
              </Button>
            </div>
            <div className="overflow-x-auto">
              <Table striped>
                <Table.Head>
                  <Table.HeadCell>შექმნის თარიღი</Table.HeadCell>
                  <Table.HeadCell>სურათი</Table.HeadCell>
                  <Table.HeadCell>სათაური</Table.HeadCell>
                  <Table.HeadCell>ტანი</Table.HeadCell>
                  <Table.HeadCell>
                    <span className="sr-only">Edit</span>
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {blogs &&
                    blogs.map((blog, index) => (
                        <AdminBlogTableRow
                          id={blog.id}
                          title={blog.title}
                          createDate={blog.createDate}
                          bodyHTML={blog.bodyHTML}
                          imageUrl={blog.imageUrl}
                          key={blog.id}
                          bodyHTMLEn={blog.bodyHTMLEn}
                          titleEn={blog.titleEn}
                          description={blog.description}
                          descriptionEn={blog.descriptionEn}
                        />
                    ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <AddBlogModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
}

export default AdminBlogs;
