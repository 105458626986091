import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const languages = [
    { code: 'ka', flag: 'https://flagsapi.com/GE/flat/64.png', label: 'ქართული' },
    { code: 'en', flag: 'https://flagsapi.com/GB/flat/64.png', label: 'English' },
];

  const handleLanguageChange = (langCode: string) => {
    i18n.changeLanguage(langCode);
    setSelectedLanguage(langCode);
    setIsOpen(false); // Close dropdown after selecting
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {/* Trigger for opening dropdown */}
      <div
        style={{
          width: '40px',
          height: '40px',
          backgroundImage: `url(${languages.find(lang => lang.code === selectedLanguage)?.flag})`,
          backgroundSize: 'cover',
          cursor: 'pointer',
        }}
        onClick={() => setIsOpen(!isOpen)}
      />
      
      {/* Custom dropdown */}
      {isOpen && (
        <div style={{
          position: 'absolute',
          top: '50px',
          left: '0',
          border: '1px solid #ccc',
          width:'128px',
          borderRadius: '5px',
          backgroundColor: 'white',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }}>
          {languages.map(lang => (
            <div
              key={lang.code}
              onClick={() => handleLanguageChange(lang.code)}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '5px 10px',
                cursor: 'pointer',
              }}
            >
              <img
                src={lang.flag}
                alt={lang.label}
                style={{ width: '24px', marginRight: '10px' }}
              />
              <span>{lang.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
