import React, { useEffect, useState } from "react";
import HeaderSpace from "../../components/Shared/HeaderSpace/HeaderSpace";
import Container from "../../components/Shared/Container/Container";
import Stats from "../../components/Stats/Stats";
import { Button, Carousel } from "flowbite-react";
import EmployeeCard from "../../components/Cards/EmployeeCard";
import { Employee } from "../../interfaces/Employee";
import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import Spinner from "../../components/Shared/Spinner/Spinner";
import { AboutPhoto } from "../../interfaces/AboutPhoto";
import { scrollToContact } from "../../utils";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { Certificate } from "../../interfaces/Certificate";

function About() {
  const { t, i18n } = useTranslation(); // Initialize translation hook
  const [employees, setEmployees] = useState<Employee[]>();
  const [certificates, setCertificates] = useState<Certificate[]>();
  const [aboutPhotos, setAboutPhotos] = useState<AboutPhoto[]>();
  const [aboutData, setAboutData] = useState<DocumentData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      // Fetch employees data
      const querySnapshot = await getDocs(collection(db, "employees"));
      const items = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Employee)
      );
      setEmployees(items);

      const certQuerySnapshot = await getDocs(collection(db, "certificates"));
      const certs = certQuerySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Certificate)
      );
      setCertificates(certs);

      // Fetch about data from pages collection
      const aboutDocRef = doc(db, "pages", "about");
      const aboutDocSnapshot = await getDoc(aboutDocRef);

      if (aboutDocSnapshot.exists()) {
        setAboutData(aboutDocSnapshot.data());
      } else {
        console.log("No such document in pages/about!");
      }

      // Fetch about photos data
      const querySnapshotAbout = await getDocs(collection(db, "about"));
      const aboutItems = querySnapshotAbout.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as AboutPhoto)
      );
      setAboutPhotos(aboutItems);
    };

    fetchData();
  }, []);

  if (!employees || !aboutPhotos || !aboutData) {
    return <Spinner />;
  }

  // Determine whether to use the English or default version of the about data
  const aboutDescription =
    i18n.language === "en" ? aboutData.descriptionEn : aboutData.description;
  const aboutTitle =
    i18n.language === "en" ? aboutData.titleEn : aboutData.title;

  // Conditionally render stats based on the selected language
  const experience =
    i18n.language === "en"
      ? aboutData.stats.experienceEn
      : aboutData.stats.experience;
  const partners =
    i18n.language === "en"
      ? aboutData.stats.partnersEn
      : aboutData.stats.partners;
  const students =
    i18n.language === "en"
      ? aboutData.stats.studentsEn
      : aboutData.stats.students;

  return (
    <div
      className="relative bg-repeat"
      style={{
        backgroundImage: `url('/images/backgrounds/background-dots-01.png')`,
        backgroundSize: "150px 150px",
      }}
    >
      <HeaderSpace />
      <Container className="py-4 lg:py-12">
        <div className="flex flex-col gap-16">
          <div className="flex flex-col text-center">
            <h3 className="text-main-blue font-bold">
              {/* {t("whoAreWe")} */}
            </h3>
            <h2 className="text-main-red font-bold text-4xl">
              {t("aboutUs")} {/* Translation for "ჩვენ შესახებ" */}
            </h2>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 lg:gap-32">
            <div className="lg:h-full object-cover rounded-xl">
              <Carousel>
                {aboutData.images.map((img: string) => (
                  <img
                    src={img}
                    alt="about"
                    className="lg:h-full object-cover rounded-xl"
                  />
                ))}
              </Carousel>
            </div>
            <div className="flex flex-col gap-4">
              <Stats
                experience={experience}
                partners={partners}
                students={students}
              />
              <Button
                className="bg-main-red"
                color={"main-red"}
                onClick={scrollToContact}
              >
                {t("contactUs")}
              </Button>
            </div>
          </div>
        </div>
      </Container>

      {/* <Container className="py-8">
        <div className="h-56 sm:h-64 xl:h-[512px] 2xl:h-[624px]">
          <Carousel>
            {aboutPhotos &&
              aboutPhotos.map((photo) => (
                <img
                  src={photo.imageUrl}
                  key={photo.id}
                  alt="about"
                  className="object-cover"
                />
              ))}
          </Carousel>
        </div>
      </Container> */}

      <Container className="py-12 flex flex-col gap-4">
        <h2 className="text-xl lg:text-2xl font-bold text-main-blue">
          {t("aboutUs")} {/* Translation for "ჩვენი ისტორია" */}
        </h2>
        <h4>
          {i18n.language === "en" ? (
            <div>
              <h4>
                <p>
                  ELL Educational Agency was founded in 2002 in Tbilisi,
                  Georgia. Prior to this process, a two-year preparation was
                  carried out which involved signing agreements and receiving
                  certificates of representation with Universities, Colleges,
                  and Language Schools in the UK, USA, and Europe. ELL was the
                  first education agency on the Georgian market at that time.
                  Nowadays, we are regarded as one of the most professional
                  agents in Georgia, with an excellent reputation and references
                  from our clients and educational institutions.
                </p>
                <br />
                <p>
                  In 2005, ELL opened the English Language Centre, which has
                  been operating successfully as a General English and Cambridge
                  English Preparation Centre. It is aimed at students taking
                  Cambridge exams such as KET, PET, FCE, CAE, IELTS, or TOEFL.
                </p>
                <br />
                <p>
                  In 2015, in recognition of its success, the ELL Language
                  Centre was recognized by the Cambridge English Language
                  Assessment body as an official Cambridge English Preparation
                  Centre. Each year, the ELL Language Centre is honored by the
                  Cambridge Assessment body with certificates acknowledging it
                  as one of the best English language preparation centres for
                  Cambridge ESOL Examinations and IELTS.
                </p>
                <br />
                <p>
                  Since 2023, ELL English Language Centre has been a
                  Premium-level holder of the British Council Bonus Programme
                  ‘Advantage’ for preparing and registering students for
                  Cambridge Exams. Prior to this, from 2013 to 2022, ELL was a
                  Silver and Gold-level holder.
                </p>
                <br />
                <p>
                  ELL staff are highly-qualified professionals. They take part
                  in professional training and are certified counselors after
                  successfully passing a variety of international exams in the
                  educational field.
                </p>
                <br />
                <p>ELL Agency is an official partner of:</p>
                <ul className="list-disc ml-5">
                  <li>English UK partner Agency</li>
                  <li>ICEF Agency (Germany)</li>
                  <li>British Council</li>
                </ul>
                <br />
                <p>
                  ELL Education Agency participates in annual education forums
                  and meetings such as:
                </p>
                <ul className="list-disc ml-5">
                  <li>Studyworld – London</li>
                  <li>Alphe UK - London</li>
                  <li>British Boarding School Workshop - UK</li>
                  <li>English UK workshops</li>
                  <li>ICEF Berlin</li>
                  <li>ICEF Higher Education workshops</li>
                  <li>
                    UCAS - International Teachers and Advisers Conference London
                  </li>
                  <li>British Council International Agent Fairs</li>
                </ul>
              </h4>
            </div>
          ) : (
            <>
              <p>
                საგანმანათლებლო სააგენტო ELL – „ი ელ ელ ი“ დაარსდა 2002 წელს
                თბილისში. იმ დროისთვის ELL იყო პირველი საგანმანათლებლო სააგენტო
                საქართველოს ბაზარზე და დღეს ჩვენ უკვე მიგვიჩნევენ ერთ-ერთ
                ყველაზე გამოცდილ და პროფესიონალ სააგენტოდ, კარგი რეპუტაციით და
                შესანიშნავი რეკომენდაციით ჩვენი კლიენტებისა და საგანმანათლებლო
                ინსტიტუციების მხრიდან.
              </p>
              <br />
              <p>
                2005 წელს საგანმანათლებლო სააგენტოს ბაზაზე დაარსდა ELL –ის
                ინგლისური ენის ცენტრი. მას შემდეგ ცენტრში ფუნქციონირებს ზოგადი
                ინგლისური ენისა და საერთაშორისო გამოცდების მოსამზადებელი
                კურსები: KET, PET, FCE, CAE, IELTS და ასევე TOEFL.
              </p>
              <br />
              <p>
                2015 წელს Cambridge English Language Assessment -მა, ELL -ის
                ინგლისური ენის ცენტრი აღიარა საქართველოში საუკეთესო ენის
                ცენტრად, რომელიც ამზადებს სტუდენტებს კემბრიჯის საერთაშორისო
                გამოცდებისათვის და მიანიჭა მას შესაბამისი კვალიფიკაციის
                სერთიფიკატი. ყოველწლიურად ELL -ის ინგლისური ენის ცენტრს
                გადაეცემა სერთიფიკატი, რომელიც ადასტურებს ELL-ის პარტნიორობას
                კემბრიჯის საერთაშორისო შეფასების ცენტრთან და აღიარებს მას როგორც
                საუკეთესო ინგლისური ენის სასწავლო ცენტრს, რომელიც ამზადებს
                სტუდენტებს კემბრიჯის გამოცდებისთვის და IELTS-ისთვის.
              </p>
              <br />
              <p>
                2013 წლიდან ELL -ის ინგლისური ენის ცენტრი არის ბრიტანეთის საბჭოს
                ბონუს პროგრამის „Advantage” -ის წევრი. 2013-2023 წლებში ცენტრი
                გახდა ამ პროგრამის ვერცხლის, ხოლო შემდგომში ოქროს დონის
                მფლობელი. 2024 წელს ELL-მა მოიპოვა წევრობის პრემიუმ
                (PREMIUM) დონე, ყოველწლიურად დარეგისტრირებული კანდიდატების
                რაოდენობის და წარმატების გაზრდით და ბრიტანეთის საბჭოსთან
                პარტნიორობით.
              </p>
              <br />
              <p>
                ELL თითოეული თანამშრომელი მაღალკვალიფიციური პროფესიონალია.
                მათ გავლილი აქვთ სხვადასხვა ტრენინგები, ჩაბარებული აქვთ
                საერთაშორისო გამოცდები განათლების სფეროში და იწოდებიან
                სერთიფიცირებულ განათლების მენეჯერებად (Counselor), რაც
                დადასტურებულია შესაბამისი სერთიფიკატებით.
              </p>
              <br />
              <p>ELL არის ოფიციალური პარტნიორი შემდეგი ორგანიზაციების:</p>
              <ul className="list-disc">
                <li>English UK partner Agency</li>
                <li>ICEF Agency (Germany)</li>
                <li>British Council</li>
              </ul>
              <br />
              <p>
                საერთაშორისო საგანმანათლებლო ფორუმები და შეხვედრები, რომელშიც
                ELL მონაწილეობს:
              </p>
              <ul className="list-disc">
                <li>Studyworld – London</li>
                <li>Alphe UK - London</li>
                <li>British Boarding School Workshop - UK</li>
                <li>English UK workshops</li>
                <li>ICEF Berlin</li>
                <li>ICEF Higher Education workshops</li>
                <li>
                  UCAS - International Teachers and Advisers Conference London
                </li>
                <li>British Council International Agent Fairs</li>
              </ul>
              <br />
            </>
          )}
        </h4>

        <img
          src="/images/certificates/about-certificates.png"
          alt="certificates"
          className="max-h-68 max-w-[424px]"
        />
      </Container>
      {/* <Container className="py-8 flex flex-col gap-8">
        <h2 className="text-xl lg:text-2xl font-bold text-main-blue">
          {t("certificates")}
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-6 gap-8">
          {certificates &&
            certificates.map((cert) => (
              <div className="flex flex-col gap-2" key={cert.id}>
                <img
                  src={cert.imageUrl}
                  alt={cert.name}
                  className="h-64 w-full object-cover"
                />
                <div className="text-center text-sm">
                  {i18n.language === "en" ? cert.nameEn : cert.name}
                </div>
              </div>
            ))}
        </div>
      </Container> */}
      <div className="py-12 bg-main-red">
        <Container className="flex flex-col gap-8">
          <div>
            <h4 className="text-gray-200 font-bold lg:text-lg">
              {/* {t("meetOurTeam")} */}
            </h4>
            <h2 className="text-white font-bold text-2xl lg:text-4xl">
              {/* {t("ourExpertTeam")}{" "} */}
              {/* Translation for "ჩვენ ვართ გამოცდილთა გუნდი" */}
            </h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
            {employees &&
              employees
                .sort((a, b) => a.order - b.order)
                .map((employee) => (
                  <EmployeeCard
                    key={employee.id}
                    // Use English fields if the language is set to "en", otherwise fallback to the default language fields
                    name={
                      i18n.language === "en"
                        ? employee.nameEn || employee.name
                        : employee.name
                    }
                    imageUrl={employee.imageUrl}
                    // Ensure roles are valid arrays before calling join
                    role={
                      i18n.language === "en"
                        ? Array.isArray(employee.rolesEn) &&
                          employee.rolesEn.length > 0
                          ? employee.rolesEn.join(", ")
                          : employee.roles.join(", ")
                        : Array.isArray(employee.roles) &&
                          employee.roles.length > 0
                        ? employee.roles.join(", ")
                        : ""
                    }
                  />
                ))}
          </div>
        </Container>
      </div>
    </div>
  );
}

export default About;
