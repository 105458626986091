import React from 'react';

function Privacy() {
  return (
    <div style={{ padding: '2rem', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h1>Privacy Policy</h1>
      <p>
        Welcome to <strong>ell.ge</strong>. Your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your personal information.
      </p>

      <h2>1. Information We Collect</h2>
      <p>
        We may collect personal information, such as your name, email address, and usage data when you use our services.
      </p>

      <h2>2. How We Use Your Information</h2>
      <p>
        The information we collect is used to:
      </p>
      <ul>
        <li>Provide and maintain our services</li>
        <li>Improve user experience</li>
        <li>Send updates and promotional content</li>
      </ul>

      <h2>3. Data Security</h2>
      <p>
        We implement reasonable security measures to protect your information from unauthorized access or disclosure.
      </p>

      <h2>4. Cookies</h2>
      <p>
        We use cookies to enhance your experience. You can disable cookies through your browser settings.
      </p>

      <h2>5. Your Rights</h2>
      <p>
        You have the right to access, modify, or delete your personal data. Please contact us at{' '}
        <a href="mailto:support@ell.ge">support@ell.ge</a> for any requests.
      </p>

      <h2>6. Changes to This Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Changes will be posted on this page with the updated date.
      </p>

      <p>
        <strong>Last updated:</strong> {new Date().toLocaleDateString()}
      </p>
    </div>
  );
}

export default Privacy;
