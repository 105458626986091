import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import { number, object, string } from 'yup';
import { AboutPhoto } from '../../../../interfaces/AboutPhoto';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { db } from '../../../../firebase/firebaseConfig';
import { deleteDoc, doc, setDoc, updateDoc } from 'firebase/firestore';
import { Button, FileInput, Label, Modal, TextInput } from 'flowbite-react';

interface EditAboutPhotoModal {
    aboutPhoto: AboutPhoto;
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  }

function EditAboutPhotoModal({aboutPhoto, openModal, setOpenModal}: EditAboutPhotoModal) {
    const [order, setOrder] = useState(aboutPhoto.order);
    const [imageUrl, setImageUrl] = useState(aboutPhoto.imageUrl);
  
    const [file, setFile] = useState<File | null>(null);
  
    const [errorMessages, setErrorMessages] = useState<string[]>();
  
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
  
    const navigate = useNavigate();
  
    let aboutPhotoSchema = object({
      order: number().min(0, "რიგი არ უნდა იყოს 0-ზე ნაკლები!").required("რიგის ნომერი აუცილებელია!"),
      imageUrl: string().required("სურათი აუცილებელია!"),
    });
  
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        setFile(event.target.files[0]);
      }
    };
  
    const handleSave = async () => {
      if (!aboutPhoto) {
        setErrorMessages(["No about photo selected for editing."]);
        return;
      }
  
      try {
        let imageUrl = aboutPhoto.imageUrl;
  
        if (file) {
          const storage = getStorage();
          const storageRef = ref(storage, `images/${file.name}`);
          await uploadBytes(storageRef, file);
          imageUrl = await getDownloadURL(storageRef);
        }
  
        await aboutPhotoSchema.validate({
          imageUrl,
          order
        });
  
        const newAboutPhoto: AboutPhoto = {
          id: aboutPhoto.id,
          imageUrl,
          order
        };
  
        const aboutPhotosDocRef = doc(db, "about", aboutPhoto.id);
        await setDoc(aboutPhotosDocRef, newAboutPhoto, { merge: true });
        await updateDoc(aboutPhotosDocRef, newAboutPhoto as { [x: string]: any });
  
        setOpenModal(false);
        navigate(0);
      } catch (e: any) {
        console.error(e);
        let messages = e.errors;
        setErrorMessages(messages);
      }
    };
  
    const handleDelete = async (photoId: string) => {
      setButtonsDisabled(true);
      try {
        const aboutPhotosDocRef = doc(db, "about", photoId);
        await deleteDoc(aboutPhotosDocRef);
        navigate(0); // Refresh the page or update the state to reflect changes
      } catch (error) {
        console.error("Error deleting document: ", error);
      }
      setButtonsDisabled(false);
    };
  
    return (
      <>
        <Modal
          dismissible
          show={openModal}
          onClose={() => setOpenModal(false)}
          className="font-mtavvruli"
        >
          <Modal.Header className="text-main-blue font-bold">
            შეცვალე <span className="text-main-red">სურათი</span>
          </Modal.Header>
          <Modal.Body>
            <div className="space-y-6 w-full">
              <div className="flex flex-col gap-4">
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="base" value="რიგის ნომერი" />
                  </div>
                  <TextInput
                    id="base"
                    type="text"
                    sizing="md"
                    value={order}
                    onChange={(e) => setOrder(Number(e.target.value))}
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="file" value="ატვირთე სურათი" />
                  </div>
                  <FileInput
                    id="file"
                    helperText={aboutPhoto.imageUrl ? aboutPhoto.imageUrl : "სურათი"}
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>
            {errorMessages &&
              errorMessages?.map((message) => (
                <h3 className="text-main-red font-bold">{message}</h3>
              ))}
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="main-red"
              onClick={() => setOpenModal(false)}
              disabled={buttonsDisabled}
            >
              დახურვა
            </Button>
            <Button color="gray" onClick={handleSave} disabled={buttonsDisabled}>
              შენახვა
            </Button>
            <Button
              color={"warning"}
              onClick={() => handleDelete(aboutPhoto.id)}
              disabled={buttonsDisabled}
            >
              წაშლა
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default EditAboutPhotoModal