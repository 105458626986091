import React, { useState } from "react";
import HeaderSpace from "../../../components/Shared/HeaderSpace/HeaderSpace";
import { Button, Checkbox, Label, TextInput } from "flowbite-react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (
      username === process.env.REACT_APP_ADMIN_USERNAME &&
      password === process.env.REACT_APP_ADMIN_PASSWORD
    ) {
      // Set the userToken cookie
      Cookies.set("userToken", "ghost-cookies", { expires: 7 }); // Expires in 7 days

      // Redirect to a protected route or perform other actions upon successful login
      navigate("/admin");
    } else {
      // Handle login failure (e.g., show an error message)
      alert("ელ. ფოსტა ან პაროლი არასწორია!");
    }
  };

  return (
    <>
      <div className="flex flex-col h-screen">
        <HeaderSpace className="bg-white" />
        <div className="flex-1 flex flex-col justify-between items-center min-w-60">
          <form
            className="flex w-96 flex-col gap-4 h-full justify-center"
            onSubmit={handleSubmit}
          >
            <div className="py-4 flex flex-col gap-2 items-center">
              <h4 className="text-main-blue font-bold text-2xl lg:text-main-4xl">
                სისტემაში შესვლა
              </h4>
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="email1"
                  value="ელ. ფოსტა"
                  className="text-main-blue font-bold"
                />
              </div>
              <TextInput
                id="email1"
                type="email"
                placeholder="ჩაწერეთ თქვენი ელ. ფოსტა..."
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="password1"
                  value="პაროლი"
                  className="text-main-blue font-bold"
                />
              </div>
              <TextInput
                id="password1"
                type="password"
                placeholder="ჩაწერეთ თქვენი პაროლი..."
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <Button type="submit">შესვლა</Button>
            <div className="flex justify-center">
              <img
                src="/images/logos/ell-logo.png"
                alt="Ell"
                className="max-h-24 max-w-24"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
