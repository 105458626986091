import React, { useEffect, useRef, useState } from "react";
import HeaderSpace from "../../components/Shared/HeaderSpace/HeaderSpace";
import Container from "../../components/Shared/Container/Container";
import { Button, Label, Select } from "flowbite-react";
import { REGIONS } from "../../enums/regions";
import {
  collection,
  DocumentData,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { University } from "../../interfaces/University";
import Spinner from "../../components/Shared/Spinner/Spinner";
import { Partner } from "../../interfaces/Partner";
import { useTranslation } from "react-i18next"; // Import useTranslation

function Universities() {
  const { t, i18n } = useTranslation(); // Initialize translation hook with i18n to detect current language
  const [region, setRegion] = useState(REGIONS.Europe); // Default to Europe
  const [countrySlug, setCountrySlug] = useState<string>(""); // Initialize slug as empty string
  const [country, setCountry] = useState<string>(""); // Initialize country ID as empty string
  const [bodyHTML, setBodyHTML] = useState<string>(""); // Initialize HTML body for display

  const [find, setFind] = useState(false);
  const [allCountries, setAllCountries] = useState<University[]>();
  const [allPartners, setAllPartners] = useState<Partner[]>();

  useEffect(() => {
    const fetchData = async () => {
      let querySnapshot = await getDocs(collection(db, "universities"));
      let items = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as University)
      );
      setAllCountries(items);
      const defaultCountry = items.filter(
        (x) => x.region === REGIONS.Europe
      )[0];
      setCountrySlug(defaultCountry?.slug || ""); // Provide fallback if undefined
      setCountry(defaultCountry?.id || ""); // Provide fallback if undefined

      querySnapshot = await getDocs(collection(db, "partners"));
      const partnerItems = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Partner)
      );

      setAllPartners(partnerItems);
    };

    fetchData();
  }, []);

  const handleRegionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedRegion = e.target.value;
    setRegion(selectedRegion);
    const selectedCountry = allCountries?.filter(
      (x) => x.region === selectedRegion
    )[0];
    setCountry(selectedCountry?.id || ""); // Fallback to empty string if no country found
    setCountrySlug(selectedCountry?.slug || ""); // Fallback to empty string if no slug found
  };

  const handleFindUniversities = () => {
    const selectedUniversity = allCountries?.filter((x) => x.id === country)[0];
    // Fallback logic to check for English fields
    setBodyHTML(
      i18n.language === "en"
        ? selectedUniversity?.bodyHTMLEn || selectedUniversity?.bodyHTML || ""
        : selectedUniversity?.bodyHTML || ""
    );
    setFind(true);
  };

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const anchors = containerRef.current.querySelectorAll("a");
      anchors.forEach((anchor) => {
        anchor.style.color = "red";
      });
    }
  }, [bodyHTML]);

  if (!country || !region || !allPartners) {
    return <Spinner />;
  }

  return (
    <div
      className="relative bg-repeat"
      style={{
        backgroundImage: `url('/images/backgrounds/background-dots-01.png')`,
        backgroundSize: "150px 150px",
      }}
    >
      <HeaderSpace />
      <Container className="py-4 lg:py-12 flex flex-col gap-8 lg:gap-16">
        <div className="flex flex-col gap-16">
          <div className="flex flex-col text-center">
            {/* <h3 className="text-main-blue font-bold">{t("studyAnywhere")}</h3> */}
            <h2 className="text-main-red font-bold text-4xl">
              {t("ourPartners")}
            </h2>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          <div>
            <h3 className="text-main-blue font-bold text-xl lg:text-4xl">
              {/* {t("friendshipBegins")} */}
            </h3>
            <div
              className="mt-8 text-sm lg:text-lg"
              dangerouslySetInnerHTML={{ __html: t("partnerDescription") }}
            />
          </div>
          <div className="grid grid-cols-3  md:grid-cols-3 lg:grid-rows-4 lg:grid-cols-5 gap-4">
            {allPartners &&
              allPartners.map((partner) => (
                <div className="flex items-center justify-center">
                  <img
                    src={partner.imageUrl}
                    key={partner.id}
                    alt="partner uni"
                  />
                </div>
              ))}
          </div>
        </div>
        {/* <div className="flex flex-col gap-8">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            <div className="flex flex-col justify-end">
              <div className="mb-2 block">
                <Label htmlFor="region" value={t("selectRegion")} />
              </div>
              {region && (
                <Select id="region" onChange={handleRegionChange} required>
                  <option value={REGIONS.Europe}>{t('europe')}</option>
                  <option value={REGIONS.Asia}>{t('asia')}</option>
                  <option value={REGIONS.NorthAmerica}>{t('northAmerica')}</option>
                  <option value={REGIONS.Oceania}>{t('oceania')}</option>
                </Select>
              )}
            </div>
            <div>
              <div className="mb-2 flex items-center gap-2">
                <img
                  src={`https://flagsapi.com/${countrySlug}/shiny/64.png`}
                  className="h-8"
                  alt="country"
                />
                <Label htmlFor="country" value={t("selectCountry")} />
              </div>
              {country && (
                <Select
                  className="rounded-l-none"
                  id="country"
                  onChange={(e) => {
                    const selectedCountry = allCountries?.filter((country) => country.id === e.target.value)[0];
                    setCountry(e.target.value || ""); // Fallback value
                    setCountrySlug(selectedCountry?.slug || ""); // Fallback value
                  }}
                  required
                >
                  {region &&
                    allCountries &&
                    allCountries.length !== 0 &&
                    allCountries
                      .filter((country) => country.region === region)
                      .sort()
                      .map((country) => (
                        <option key={country.id} value={country.id}>
                          {i18n.language === "en" ? country.nameEn || country.name : country.name}
                        </option>
                      ))}
                  {allCountries?.filter((country) => country.region === region)
                    .length === 0 && (
                    <option value={"default disabled"} disabled>
                      {t("noUniversitiesFound")}
                    </option>
                  )}
                </Select>
              )}
            </div>
            <div className="w-full flex flex-col justify-end mt-8 lg:mt-0">
              <Button
                onClick={handleFindUniversities}
                className="max-h-[42px] w-full bg-main-red"
                color={"main-red"}
              >
                {t("findUniversities")}
              </Button>
            </div>
          </div>
          {!find && (
            <div
              onClick={handleFindUniversities}
              className="py-24 border-2 border-solid border-main-red rounded-2xl 
              flex flex-col justify-center items-center bg-main-background
              hover:bg-main-red hover:text-white hover:cursor-pointer duration-150 group"
            >
              <MdFindInPage className="h-32 w-32 lg:h-48 lg:w-48 text-gray-600 group-hover:text-white duration-150" />
              <h4 className="text-gray-600 font-bold group-hover:text-white lg:text-xl duration-150">
                {t("searchUniversities")}
              </h4>
            </div>
          )}
          {find && (
            <div
              dangerouslySetInnerHTML={{ __html: bodyHTML }}
              ref={containerRef}
              className="min-h-80 text-ellipsis overflow-x-hidden"
            />
          )}
        </div> */}
      </Container>
    </div>
  );
}

export default Universities;
