import classNames from 'classnames'
import React from 'react'

interface HeaderSpaceProps {
  className?: string;
}

function HeaderSpace({className}: HeaderSpaceProps) {
  return (
    <div className={classNames('header_space', className)}></div>
  )
}

export default HeaderSpace