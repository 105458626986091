import { CustomFlowbiteTheme } from "flowbite-react";

const lightTheme: CustomFlowbiteTheme = {
    button: {
      color: {
        "main-red": "bg-[#ae172a] hover:bg-[#DF0029] text-white",
        "main-blue": "bg-[#274C77] hover:bg-[#F74C77]"
      },
    },
    megaMenu: {
      dropdown: {
        toggle: {
          arrowIcon: 'text-white'
        }
      },
      root: {
        base: 'px-0 py-2.5'
      }
    },
};

export default lightTheme;