import React, { useState } from "react";
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import App from "./App";
import Home from "./screens/Home/Home";
import Error404 from "./screens/Error404/Error404";
import Services from "./screens/Services/Services";
import About from "./screens/About/About";
import Contact from "./screens/Contact/Contact";
import Blog from "./screens/Blog/Blog";
import ViewBlog from "./screens/Views/ViewBlog/ViewBlog";
import Students from "./screens/Students/Students";
import Universities from "./screens/Universities/Universities";
import AdminHome from "./screens/Admin/Home/AdminHome";
import AdminStudents from "./screens/Admin/Students/AdminStudents";
import AdminTeam from "./screens/Admin/Team/AdminTeam";
import AdminMails from "./screens/Admin/Mails/AdminMails";
import AdminBlogs from "./screens/Admin/Blogs/AdminBlogs";
import AdminUniversities from "./screens/Admin/Universities/AdminUniversities";
import AdminAboutPhotos from "./screens/Admin/AboutPhotos/AdminAboutPhotos";
import FAQ from "./screens/FAQ/FAQ";
import AdminFaq from "./screens/Admin/FAQ/AdminFaq";
import Login from "./screens/Admin/Login/Login";
import IsAuthenticatedPrivateRoute from "./utils";
import AdminPartners from "./screens/Admin/Partners/AdminPartners";
import EnglishCenter from "./screens/EnglishCenter/EnglishCenter";
import AdminServices from "./screens/Admin/Services/AdminServices";
import AdminEnglishCenter from "./screens/Admin/EnglishCenter/AdminEnglishCenter";
import AdminCertificates from "./screens/Admin/Certificates/AdminCertificates";
import PartnerUniversities from "./screens/Admin/PartnerUniversities/PartnerUniversities";
import Fares from "./screens/Fares/Fares";
import AdminFarePhotos from "./screens/Admin/FarePhotos/AdminFarePhotos";
import Privacy from "./screens/Privacy/Privacy";

function AppRouter() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<App />}>
        <Route index={true} path="/" element={<Home />}></Route>
        <Route index={true} path="/services" element={<Services />}></Route>
        <Route index={true} path="/english-center" element={<EnglishCenter />}></Route>
        <Route index={true} path="/about" element={<About />}></Route>
        <Route index={true} path="/contact" element={<Contact />}></Route>
        <Route index={true} path="/faq" element={<FAQ />}></Route>
        <Route index={true} path="/fares" element={<Fares />}></Route>
        <Route index={true} path="/privacy" element={<Privacy />}></Route>
        <Route path="blog">
          <Route index={true} element={<Blog />} />
          <Route path=":id" element={<ViewBlog />} />
        </Route>
        <Route index={true} path="/students" element={<Students />}></Route>
        <Route
          index={true}
          path="/universities"
          element={<Universities />}
        ></Route>

        {/* <Route index={true} path="/faq" element={<FAQ />}></Route> */}
        <Route index={true} path="/login" element={<Login />}></Route>
        <Route path="/admin">
          <Route index={true} path=""  element={<IsAuthenticatedPrivateRoute Component={<AdminHome />} />}></Route>
          <Route
            path="students"
            element={<IsAuthenticatedPrivateRoute Component={<AdminStudents />} />}
          ></Route>
          <Route path="team" element={<IsAuthenticatedPrivateRoute Component={<AdminTeam />} />}></Route>
          <Route path="mails" element={<IsAuthenticatedPrivateRoute Component={<AdminMails />} />}></Route>
          <Route path="blogs" element={<IsAuthenticatedPrivateRoute Component={<AdminBlogs />} />}></Route>
          <Route path="aboutPhotos" element={<IsAuthenticatedPrivateRoute Component={<AdminAboutPhotos />} />}></Route>
          <Route path="faq" element={<IsAuthenticatedPrivateRoute Component={<AdminFaq />} />}></Route>
          <Route path="universities" element={<IsAuthenticatedPrivateRoute Component={<AdminUniversities />} />}></Route>
          <Route path="partners" element={<IsAuthenticatedPrivateRoute Component={<AdminPartners />} />}></Route>
          <Route path="services" element={<IsAuthenticatedPrivateRoute Component={<AdminServices />} />}></Route>
          <Route path="englishCenter" element={<IsAuthenticatedPrivateRoute Component={<AdminEnglishCenter />} />}></Route>
          <Route path="certificates" element={<IsAuthenticatedPrivateRoute Component={<AdminCertificates />} />}></Route>
          <Route path="partnerUniversities" element={<IsAuthenticatedPrivateRoute Component={<PartnerUniversities />} />}></Route>
          <Route path="fares" element={<IsAuthenticatedPrivateRoute Component={<AdminFarePhotos />} />}></Route>
        </Route>
        <Route index={true} path="*" element={<Error404 />}></Route>
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default AppRouter;
