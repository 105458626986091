import React, { useEffect, useState } from "react";
import Container from "../../Shared/Container/Container";
import { Spinner } from "flowbite-react";
import { Student } from "../../../interfaces/Student";
import {
  collection,
  DocumentData,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { db } from "../../../firebase/firebaseConfig";
import { useTranslation } from "react-i18next";
import FullscreenSlidingCarousel from "../../FullscreenSlidingCarousel/FullscreenSlidingCarousel";

function MainStudents() {
  const { t, i18n } = useTranslation(); // Initialize translation hook
  const [students, setStudents] = useState<Student[]>();

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "students"));
      const items = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Student)
      );
      setStudents(items);
    };

    fetchData();
  }, []);

  if (!students) {
    return <Spinner />;
  }

  return (
    <div className="bg-main-blue py-8">
      <Container className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <h3 className="text-center text-gray-300 text-sm lg:text-xl">
            {/* {t("whatAreThaySaying")}  */}
          </h3>
          <h2 className="text-center text-white text-xl lg:text-4xl">
            {t("ourStudents")}
            {/* Translation for "ჩვენი კურსდამთავრებულები" */}
          </h2>
        </div>
      </Container>
      <FullscreenSlidingCarousel>
        {students &&
          students.map((student) => (
            <div
              key={student.id}
              className="flex px-2 flex-col items-center w-64 text-center"
            >
              <img
                src={student.imageUrl}
                alt="std"
                className="w-48 h-48 object-cover rounded-lg"
              />
              <h4 className="text-sm mt-2 text-white">
                {i18n.language === "en" ? student.nameEn : student.name}
              </h4>
              <h4
                className="text-sm text-gray-300 break-normal leading-tight whitespace-normal overflow-hidden text-ellipsis"
                style={{ maxWidth: "100%" }}
              >
                {student.jobs[0]}
              </h4>
            </div>
          ))}
      </FullscreenSlidingCarousel>
    </div>
  );
}

export default MainStudents;
