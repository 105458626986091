import { Button, Label, Modal, TextInput, FileInput } from "flowbite-react";
import React, { useState } from "react";
import { Partner } from "../../../../interfaces/Partner";
import { useNavigate } from "react-router";
import { object, string } from "yup";
import { deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../../../firebase/firebaseConfig"; // Import Firebase storage
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Import Firebase storage methods

interface EditPartnerModalProps {
  partner: Partner;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function EditPartnerModal({ partner, openModal, setOpenModal }: EditPartnerModalProps) {
  const [imageUrl, setImageUrl] = useState(partner.imageUrl || "");
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [uploading, setUploading] = useState(false); // Track upload state
  const navigate = useNavigate();

  // Validation schema for partner
  let partnerSchema = object({
    imageUrl: string().required("სურათის URL აუცილებელია!"),
  });

  // Handle file input change to update imageUrl
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Start upload
      setUploading(true);
      const storageRef = ref(storage, `partners/${partner.id}/image`); // Define the storage path

      try {
        // Upload file to Firebase storage
        await uploadBytes(storageRef, file);
        // Get the file's download URL
        const downloadUrl = await getDownloadURL(storageRef);
        // Set the imageUrl state with the download URL
        setImageUrl(downloadUrl);
      } catch (error) {
        console.error("Image upload failed:", error);
        setErrorMessages(["სურათის ატვირთვა ვერ მოხერხდა. გთხოვთ, ცადოთ თავიდან."]);
      } finally {
        setUploading(false); // End upload
      }
    }
  };

  const handleSave = async () => {
    if (!partner) {
      setErrorMessages(["არცერთი პარტნიორი არ არის არჩეული შესაცვლელად."]);
      return;
    }

    try {
      await partnerSchema.validate({ imageUrl });

      const updatedPartner: Partner = {
        id: partner.id,
        imageUrl,
      };

      const partnerDocRef = doc(db, "partners", updatedPartner.id);
      await setDoc(partnerDocRef, updatedPartner, { merge: true });
      await updateDoc(partnerDocRef, updatedPartner as { [x: string]: any });

      setOpenModal(false);
      navigate(0);
    } catch (e: any) {
      let messages = e.errors;
      setErrorMessages(messages);
    }
  };

  const handleDelete = async (partnerId: string) => {
    setButtonsDisabled(true);
    try {
      const partnerDocRef = doc(db, "partners", partnerId);
      await deleteDoc(partnerDocRef);
      navigate(0); // Refresh the page or update the state to reflect changes
    } catch (error) {
      console.error("დოკუმენტის წაშლის შეცდომა: ", error);
    }
    setButtonsDisabled(false);
  };

  return (
    <Modal
      dismissible
      show={openModal}
      onClose={() => setOpenModal(false)}
      className="font-mtavvruli"
    >
      <Modal.Header className="text-main-blue font-bold">პარტნიორის რედაქტირება</Modal.Header>
      <Modal.Body>
        <div className="space-y-6 w-full">
          <div className="flex flex-col gap-4">
            {/* File Input for Image Upload */}
            <div>
              <div className="mb-2 block">
                <Label htmlFor="file" value="ატვირთე სურათი" />
              </div>
              <FileInput
                id="file"
                helperText={imageUrl ? imageUrl : "არჩევა ან ატვირთვა"}
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>
        {errorMessages &&
          errorMessages.map((message) => (
            <h3 className="text-main-red font-bold" key={message}>
              {message}
            </h3>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="main-red"
          onClick={() => setOpenModal(false)}
          disabled={buttonsDisabled || uploading} // Disable button when uploading
        >
          დახურვა
        </Button>
        <Button color="gray" onClick={handleSave} disabled={buttonsDisabled || uploading}>
          {uploading ? "ატვირთვა..." : "შენახვა"}
        </Button>
        <Button
          color={"warning"}
          onClick={() => handleDelete(partner.id)}
          disabled={buttonsDisabled || uploading} // Disable button when uploading
        >
          წაშლა
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditPartnerModal;
