import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBwgrBhoK6uRCtNPDPbaDiZTUo7or1j7fs",
    authDomain: "ell-website.firebaseapp.com",
    databaseURL: "https://ell-website-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "ell-website",
    storageBucket: "ell-website.appspot.com",
    messagingSenderId: "187066134530",
    appId: "1:187066134530:web:abf6b4abe81e311675371b",
    measurementId: "G-8GDG22PM8M"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
